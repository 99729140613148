.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  min-width: 310px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 20rem;
}

.face {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-container p {
  font-size: 1.1rem;
  margin: 0;
  padding: 0.1rem;
  text-align: center;
  max-width: 90%;
}

.flag-container {
  border: 2px solid black;
  margin-bottom: 0.5rem;
}

.outer {
  width: 22rem;
  height: 22rem;
  border: 2px solid black;
  background: #021844;
  pointer-events: none;
}

.inner {
  width: 18rem;
  height: 18rem;
  border: 2px solid black;
  background-color: white;
  pointer-events: none;
}

.box {
  pointer-events: none;
  box-sizing: border-box;
  position: absolute;
  border-radius: 50%;
}

.clock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 22.1rem;
  height: 22.1rem;

  &.hour {
    transform: rotate(150deg);
    z-index: 1;
  }

  &.minute {
    animation-name: rotateMinute;
    animation-duration: 3600s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 2;
  }

  &.second {
    animation-name: rotateSecond;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 3;
  }

  &.milliSecond {
    animation-name: rotateMilliSecond;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 4;
  }
}

#dot {
  &-hour {
    height: 31px;
    width: 20px;
  }

  &-minute {
    height: 31px;
    width: 15px;
  }

  &-second {
    height: 31px;
    width: 10px;
  }

  &-milliSecond {
    height: 31px;
    width: 5px;
  }
}

.dot {
  background-color: white;
  position: absolute;
  border: 2px solid black;
}

@media only screen and (max-width: 500px) {
  .text-container {
    width: 300px;
    height: 300px;
  }

  .outer {
    width: 300px;
    height: 300px;
  }

  .inner {
    width: 245.4545454px;
    height: 245.4545454px;
  }

  .clock {
    width: 301.36363636px;
    height: 301.36363636px;
  }

  #dot {
    &-hour {
      height: 27px;
      width: 20px;
    }

    &-minute {
      height: 27px;
      width: 15px;
    }

    &-second {
      height: 27px;
      width: 10px;
    }

    &-milliSecond {
      height: 27px;
      width: 5px;
    }
  }
}
